import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "status", "submit", "spinner" ]

  submit(event) {
    event.preventDefault()
    this.spinnerTarget.classList.remove('hidden')
    this.submitTargets.forEach(el => el.disabled = true)
    this.statusTarget.value = event.target.dataset.status
    Rails.fire(this.formTarget, 'submit')
  }
}
