import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  set(event) {
    this.toggleInput()
    this.toggleState()
  }

  // private

  toggleInput() {
    this.inputTarget.value = this.newState()
  }

  toggleState() {
    this.state = this.newState()
  }

  newState() {
    return (this.state == 'true') ? 'false' : 'true'
  }

  get state() {
    return this.data.get("state")
  }

  set state(boolean) {
    this.data.set("state", boolean)
  }
}
