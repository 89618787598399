import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "siteReset", "siteResetCheckbox", "option", "optionCheckbox", "submit" ]

  toggleOptions() {
    this.optionCheckboxTargets.forEach(element => element.disabled = !element.disabled)
    this.optionTargets.forEach(element => element.classList.toggle("muted"))
    this.toggleSubmit()
  }

  toggleFullReset() {
    if(this.optionCheckboxTargets.some(this.isChecked)) {
      // disable
      this.siteResetCheckboxTargets.forEach(this.disable)
      this.siteResetTargets.forEach(this.mute)
    } else {
      // enable
      this.siteResetCheckboxTargets.forEach(this.enable)
      this.siteResetTargets.forEach(this.unmute)
    }
    this.toggleSubmit()
  }

  toggleSubmit() {
    if(this.optionCheckboxTargets.some(this.isChecked) || this.siteResetCheckboxTargets.some(this.isChecked)) {
      this.enable(this.submitTarget)
    } else {
      this.disable(this.submitTarget)
    }
  }

  isChecked(element) {
    return element.checked
  }

  disable(input) {
    input.disabled = true
  }

  enable(input) {
    input.disabled = false
  }

  mute(element) {
    element.classList.add("muted")
  }

  unmute(element) {
    element.classList.remove("muted")
  }
}
