import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "query", "results" ]

  initialize() {
    this.search = _.debounce(this.search, 250)
  }

  connect() {
    this.search()
  }

  search() {
    fetch(this.searchUrl())
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html

        const event = new Event("search-results-loaded", { bubbles: true })
        this.resultsTarget.dispatchEvent(event)
      })
  }

  showResults() {
    this.resultsTarget.classList.add("open")
  }

  hideResults(event) {
    if(event && this.queryTarget.contains(event.target) || this.resultsTarget.contains(event.target)) return

    this.resultsTarget.classList.remove("open")
  }

  // private
  searchUrl() {
    const baseUrl   = this.data.get("url")
    const separator = baseUrl.includes("?") ? "&" : "?"
    const query     = this.query() || ""

    return `${baseUrl}${separator}${query}`
  }

  get defaultQuery() {
    return this.data.get("default-query")
  }

  query() {
    if (this.queryTarget.value) return `search=${this.queryTarget.value}`

    return this.defaultQuery
  }
}
