import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "dropdown", "checkbox" ]

  connect() {
    // Keep bootstrap dropdown open after selecting an option
    $(this.dropdownTarget).find('li').click(e => e.stopPropagation())
  }

  filter(event) {
    if(event.target.checked) {
      this.selectedCount++
    } else {
      this.selectedCount--
    }

    this.updateLabel()
  }

  reset(event) {
    event.preventDefault()
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.selectedCount = 0
    this.updateLabel()
  }

  updateLabel() {
    this.labelTarget.innerText = this.label
    this.labelTarget.classList.toggle('default', this.selectedCount == 0)
  }

  get label() {
    if(this.selectedCount > 0) {
      return `${this.selectedCount} ${this.data.get('label')} selected`
    }

    return this.data.get('label')
  }

  get selectedCount() {
    return parseInt(this.data.get('selectedCount'))
  }

  set selectedCount(count) {
    this.data.set('selectedCount', count)
  }
}
