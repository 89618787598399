import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "defaultTab", "form"]

  filter(event) {
    event.preventDefault()

    // 0. Clear the form
    this.clearForm()

    // 1. Make the tab active
    this.setActiveTab(event.currentTarget)

    // 2. Set the criteria as input values in the form
    const queue = event.currentTarget.dataset.queue

    if (queue) {
      const queueName = this.formTarget.dataset.queueName
      this.formTarget.querySelector(queueName).value = queue
    }

    // 3. Submit the form
    Rails.fire(this.formTarget, 'submit')
  }

  all(event) {
    event.preventDefault()
    this.setActiveTab(event.currentTarget)
    const resetEvent = new Event('oam:filters:reset')
    this.formTarget.dispatchEvent(resetEvent)
  }

  reset(event) {
    this.setActiveTab(this.defaultTabTarget.querySelector("a"))
  }

  // private
  setActiveTab(anchorElement) {
    this.defaultTabTarget.classList.remove("active")
    this.tabTargets.forEach(tab => tab.classList.remove("active"))
    anchorElement.parentNode.classList.add("active")
  }

  clearForm() {
    const clearEvent = new Event('oam:filters:clear')
    this.formTarget.dispatchEvent(clearEvent)
  }
}
