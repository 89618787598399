import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sourceForm", "targetForm" ]

  initialize(event) {
    const controller = this

    // Options for the observer (which mutations to observe)
    const config = { childList: true };

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList, observer) {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => controller.createInput(node))
          mutation.removedNodes.forEach(node => controller.removeInput(node))
        }
      })
    };

    // Create an observer instance linked to the callback function
    this.observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    this.observer.observe(this.sourceFormTarget, config);
  }

  createInput(node) {
    if (node.nodeName != 'INPUT') return false

    const newInput = document.createElement("input");
    newInput.setAttribute("name", this.inputNameFrom(node))
    newInput.setAttribute("type", "hidden")
    newInput.setAttribute("value", node.getAttribute("value"))

    this.targetFormTarget.insertAdjacentElement('beforeend', newInput)
  }

  removeInput(node) {
    if (node.nodeName != 'INPUT') return false

    const inputName = this.inputNameFrom(node)
    const inputValue = node.getAttribute("value")
    const inputs = this.targetFormTarget.querySelectorAll(`input[name='${inputName}'][value='${inputValue}']`)
    inputs.forEach(input => {
      input.parentNode.removeChild(input)
    })
  }

  inputNameFrom(node) {
    const sourceName = node.getAttribute("name").match(/\[.*$/)
    return `${this.targetModelName}${sourceName}`
  }

  get targetModelName() {
    return this.data.get("modelName")
  }

  disconnect() {
    this.observer.disconnect();
  }
}
