import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown", "button", "unreadCount", "controls" ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.controlsTarget.insertAdjacentHTML("afterend", html)
      })
  }

  updateIndicator() {
    const unreadCount = this.data.get("unreadCount")

    this.unreadCountTarget.textContent = unreadCount > 0 ? unreadCount : ""

    if (unreadCount > 0) {
      this.buttonTarget.classList.add("unread")
    } else {
      this.buttonTarget.classList.remove("unread")
    }
  }

  toggle() {
    if (this.dropdownTarget.classList.contains("open")) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.dropdownTarget.classList.add("open")
  }

  close(event) {
    if(event && (this.dropdownTarget.contains(event.target))) {
      return
    }

    this.dropdownTarget.classList.remove("open")
  }

  callback(event) {
    if (!event.target.href) {
      return
    }
    event.preventDefault()

    const url = event.target.href
    const callbackUrl = event.currentTarget.getAttribute("data-notifications-callback-url")
    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(callbackUrl, {
      method: "POST",
      headers: { "X-CSRF-Token": csrfToken }
    })
    .then(() => window.location = url)
  }
}
