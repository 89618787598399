import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "questionsContainer", "questionsList", "form" ]

  insertQuestion(event) {
    this.addQuestionToContainer()
    this.addInputToForm()
  }

  removeQuestion(event) {
    const questionId = event.currentTarget.getAttribute('data-question-id')
    this.formTarget.querySelector(`input[value="${questionId}"]`).remove()
    this.questionsListTarget.querySelector(`#question-${questionId}`).remove()
  }

  toggleQuestions() {
    this.questionsContainerTarget.classList.toggle('show')

    if (!this.questionsContainerTarget.classList.contains('show')) {
      this.formTarget.querySelectorAll('input[name="recommendation[question_ids][]"]').forEach(e => e.parentNode.removeChild(e))

      while (this.questionsListTarget.firstChild) {
        this.questionsListTarget.removeChild(this.questionsListTarget.firstChild)
      }
    }
  }

  // private
  addQuestionToContainer() {
    const div = document.createElement("div")
    div.setAttribute('id', `question-${event.target.getAttribute('data-question-id')}`)
    div.setAttribute('class', 'question__wrapper')

    const span = document.createElement("span")
    span.setAttribute('class', 'question__text')
    span.innerHTML = event.target.innerHTML

    const anchor = document.createElement("a")
    anchor.setAttribute('href', '#')
    anchor.setAttribute('class', 'question__remove-link')
    anchor.setAttribute('data-target', 'recommendations.removeLink')
    anchor.setAttribute('data-question-id', event.target.getAttribute('data-question-id'))
    anchor.setAttribute('data-action', 'recommendations#removeQuestion')

    const removeIcon = document.createElement("i")
    removeIcon.setAttribute('class', 'icon-remove')

    anchor.appendChild(removeIcon)

    div.appendChild(span)
    div.appendChild(anchor)

    this.questionsListTarget.insertAdjacentElement('beforeend', div)
  }

  addInputToForm() {
    const input = document.createElement("input")
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'recommendation[question_ids][]')
    input.value = event.target.getAttribute('data-question-id')
    this.formTarget.appendChild(input)
  }
}
