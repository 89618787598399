import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "activities", "form" ]

  initialize() {
    this.load = _.debounce(this.load, 250)
  }

  connect() {
    const activities = this.activitiesTarget

    // jQuery event listener
    $(this.activitiesTarget).on('oam:activity:updated', function() {
      let event = new Event('change', { bubbles: true })
      activities.dispatchEvent(event)
    })

    this.load()
  }

  load() {
    Rails.fire(this.formTarget, 'submit')
  }
}
