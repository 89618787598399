import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "element", "spinner" ]

  toggle(event) {
    this.elementTarget.classList.toggle('hidden')
    this.spinnerTarget.classList.toggle('hidden')
  }
}
