import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "spinner" ]

  toggle(event) {
    this.toggleSpinner()

    if(event.target.checked) {
      this.assign()
    } else {
      this.unassign()
    }
  }

  // private

  toggleSpinner() {
    this.checkboxTarget.classList.toggle('hidden')
    this.spinnerTarget.classList.toggle('hidden')
  }

  toggleQuestion() {
    document.querySelectorAll(this.questionSelector).forEach(function(e) {
      const checkbox = e.querySelector("td input[type='checkbox']")
      e.classList.toggle('checked', checkbox.checked)
    })
  }

  assign() {
    fetch(this.data.get("url"), {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(this.postData)
    })
    .then(response => response.json())
    .then(data => {
      this.toggleSpinner()
      this.toggleQuestion()
      this.questionCount = data["assigned_question_count"]
    })
  }

  unassign() {
    fetch(`${this.data.get("url")}/${this.questionId}`, {
      method: "DELETE",
      headers: this.headers
    })
    .then(response => response.json())
    .then(data => {
      this.toggleSpinner()
      this.toggleQuestion()
      this.questionCount = data["assigned_question_count"]
    })
  }

  get headers() {
    const csrfToken = document.querySelector("[name='csrf-token']") && document.querySelector("[name='csrf-token']").content

    return {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  }

  updateQuestionCount() {
    this.questionCountEl.textContent = this.questionCount
  }

  get postData() {
    return { question_id: this.questionId }
  }

  get questionId() {
    return this.data.get("question-id")
  }

  get questionSelector() {
    return `[data-assigned-question-question-id='${this.questionId}']`
  }

  get questionCountEl() {
    return document.querySelector(".assigned-question-count")
  }

  get questionCount() {
    return this.questionCountEl.dataset.count
  }

  set questionCount(count) {
    this.questionCountEl.dataset.count = count
    this.updateQuestionCount()
  }
}
