import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ]

  toggle(event) {
    if (this.inputTarget.value.length > 0) {
      this.buttonTargets.forEach(b => b.removeAttribute("disabled"))
    } else {
      this.buttonTargets.forEach(b => b.disabled = true)
    }
  }
}
