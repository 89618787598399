import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    const select = this.selectTarget

    $(this.selectTarget).on('select2:select select2:unselect', function() {
      let event = new Event('change', { bubbles: true })
      select.dispatchEvent(event)
    })
  }

  initialize() {
    jQuery(this.selectTarget).select2({ placeholder: this.data.get("placeholder") })
  }
}
