import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "editor" ]

  blurEditor() {
    this.editorTarget.closest(".trix-group").classList.remove("focus");
  }

  focusEditor() {
    this.editorTarget.closest(".trix-group").classList.add("focus");
  }
}
