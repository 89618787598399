import { Controller } from "stimulus"

// Keeps track of the current cursor position and inserts text at that position
export default class extends Controller {
  static targets = [ "input" ]

  initialize() {
    this.position = this.workpaper.length
  }

  updatePosition() {
    this.position = this.inputTarget.selectionStart
  }

  insert(event) {
    event.preventDefault()
    const newText = event.target.getAttribute("data-text")
    this.workpaper = `${this.beforePosition} ${newText} ${this.afterPosition}`
    this.position = this.beforePosition.length + 1 + newText.length
    this.inputTarget.dispatchEvent(new Event('input'))
  }

  get position() {
    return parseInt(this.data.get("position")) || 0
  }

  set position(value) {
    this.data.set("position", value)
  }

  get workpaper() {
    return this.inputTarget.value
  }

  set workpaper(value) {
    this.inputTarget.value = value
  }

  get beforePosition() {
    return this.workpaper.substring(0, this.position)
  }

  get afterPosition() {
    return this.workpaper.substring(this.position, this.workpaper.length)
  }
}
