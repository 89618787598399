import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "hiddenField"]

  submit(event) {
    event.preventDefault()
    const value = event.target.dataset.value
    this.hiddenFieldTarget.value = value
    Rails.fire(this.formTarget, 'submit')
  }
}
