import { Controller } from "stimulus"

export default class extends Controller {
  show() {
    const name = this.data.get("name")
    event = document.createEvent("Event")
    event.initEvent(`${name}-tab-show`, true, true)
    window.dispatchEvent(event)
  }
}
