import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "input" ]

  override(event) {
    event.preventDefault()
    this.inputTarget.value = event.target.dataset.stateEvent
    Rails.fire(this.formTarget, 'submit')
  }
}
