import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    jQuery(this.messageTarget).delay(this.timeout).fadeOut('fast')
  }

  get timeout() {
    return this.data.get("timeout") || 15000
  }
}
