import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select", "option", "hiddenOption" ]

  toggle(event) {
    if(Array.apply(null, this.optionTargets).some(option => option.selected)) {
      this.hiddenOptionTargets.forEach(element => element.classList.remove("hidden"))
    } else {
      this.hiddenOptionTargets.forEach(element => element.classList.add("hidden"))
    }
  }
}
