import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "content", "close" ]

  toggle(event) {
    event.preventDefault()

    this.contentTarget.classList.toggle("open")

    if(this.contentTarget.classList.contains("open")) {
      this.positionPopover()
      this.focus()
    }
  }

  focus(event) {
    const element = this.contentTarget.querySelector(".js-autofocus")
    element ? element.focus() : null
  }

  // Calculate the height of the popover and position it above the button
  positionPopover() {
    const height = this.contentTarget.offsetHeight
    this.contentTarget.style.top = `-${height + 10}px`
  }

  close(event) {
    if(event && (this.contentTarget.contains(event.target) || this.buttonTarget.contains(event.target))) {
      return
    }

    this.contentTarget.classList.remove("open")
  }
}
