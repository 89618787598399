import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  remove(event) {
    event.preventDefault()
    const inputName = event.currentTarget.dataset.inputName
    const inputValue = event.currentTarget.dataset.inputValue
    const input = this.formTarget.querySelector(`input[name="${inputName}"][value="${inputValue}"]`)
    input.parentNode.removeChild(input)
  }
}
