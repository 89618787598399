import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "files", "input", "submit"]

  toggleSubmit() {
    if(this.inputTarget.value.length > 0) {
      this.submitTarget.removeAttribute('disabled')
    } else {
      this.submitTarget.setAttribute('disabled', 'disabled')
    }
  }

  submit(event) {
    event.preventDefault()

    this.attachFiles(event)
  }

  attachFiles(event) {
    const csrfToken = document.querySelector("[name='csrf-token']")
    const formData = new FormData(this.formTarget)
    const files = this.filesTarget.files

    formData.delete("files[]")
    Array.from(files).forEach(file => {
      formData.append("files[]", file)
    });

    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      headers: {
        'X-CSRF-Token': csrfToken ? csrfToken.content : ''
      },
      body: formData
    }).then(response => response.text())
      .then(html => {
      document.querySelector(".report-events").insertAdjacentHTML("beforeend", html)
      document.querySelectorAll("trix-editor, input[type='file'], input[type='text']").forEach(input => { input.value = '' })
    })
  }
}
