import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'modal' ]
  static values = {
    lastKeepAliveAt: Number,
    checkSessionUrl: String,
    timeoutUrl: String
  }

  initialize() {
    // Default session timeout to 5 minutes if the request fails
    this.sessionTimeoutInSecondsValue = 5 * 60
    // Default amount of time to wait before sending a keep alive request
    this.keepAliveInterval = 5 * 60
    // Avoid duplicate keepAlive calls
    this.keepAliveCheck = _.debounce(this.keepAliveCheck, 1000).bind(this)
  }

  connect() {
    this.updateLastKeepAliveAt()
    this.fetchSessionTimeout().then(() => this.poll())
  }

  updateLastKeepAliveAt() {
    this.lastKeepAliveAt = this.currentTimestamp()
  }

  async fetchSessionTimeout() {
    return fetch(this.checkSessionUrlValue, {cache: 'no-store'})
    .then((response) => response.json())
    .then(data => {
      this.sessionTimeoutInSeconds = data["session_timeout_in"]
      this.keepAliveInterval = data["session_timeout_in"] / 4
    })
  }

  poll(secondsBeforeTimeout = 60) {
    // setTimeout requires milliseconds, all of our values are in seconds
    setTimeout(() => this.handleTimeout(), (this.sessionTimeoutInSeconds - secondsBeforeTimeout) * 1000)
  }

  handleTimeout() {
    this.fetchSessionTimeout().then(() => {
      if(this.sessionTimeoutInSeconds <= 0) {
        this.signOutUser()
      } else if(this.sessionTimeoutInSeconds <= 120){
        this.warnUser()
        this.poll(0)
      } else {
        this.poll()
      }
    })
  }

  warnUser() {
    jQuery(this.modalTarget).modal('show')
  }

  signOutUser() {
    window.location.href = this.timeoutUrlValue
  }

  // Validate the keep alive interval was passed before calling keepAlive
  keepAliveCheck() {
    if(((this.currentTimestamp() - this.lastKeepAliveAt) / 1000) >= this.keepAliveInterval) {
      this.keepAlive()
    }
  }

  keepAlive() {
    fetch('/keep_alive')
      .then(response => {
        if (response.status === 200) {
          this.updateLastKeepAliveAt()
        }
      })
  }

  // Milliseconds since epoch
  currentTimestamp() {
    return new Date().valueOf()
  }
}
