import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "errorMessage", "successMessage", "input", "inputTwo", "hiddenInput" , "hiddenInputTwo" ]

  initialize() {
    this.save = _.debounce(this.save, 1000)
  }

  save() {
    this.hiddenInputTarget.value = this.inputTarget.value

    if (this.hasHiddenInputTwoTarget) {
      this.hiddenInputTwoTarget.value = this.inputTwoTarget.value
    }

    this.submit()
  }

  submit() {
    Rails.fire(this.formTarget, 'submit')
  }

  handleError(e) {
    this.showError()
  }

  handleSuccess(e) {
    this.showSuccess()
  }

  showError() {
    this.successMessageTarget.classList.add('hidden')
    this.errorMessageTarget.classList.remove('hidden')

    setTimeout(() => {
      this.errorMessageTarget.classList.add('hidden')
    }, 5000)
  }

  showSuccess() {
    this.errorMessageTarget.classList.add('hidden')
    this.successMessageTarget.classList.remove('hidden')

    setTimeout(() => {
      this.successMessageTarget.classList.add('hidden')
    }, 5000)
  }
}
