import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "value", "form" ]

  toggle(event) {
    event.preventDefault()

    this.valueTargets.forEach(target => (target.classList.toggle("hidden")))
    this.formTarget.classList.toggle("hidden")
  }
}
