import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sentinel" ]

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.intersectionObserver.observe(this.sentinelTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.sentinelTarget)
  }

  // private
  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        fetch(this.data.get("url"))
          .then(response => response.text())
          .then(html => {
            this.element.insertAdjacentHTML('afterend', html)
            this.element.remove()
          })
      }
    })
  }
}
