import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  initialize() {
    this.submit = _.debounce(this.submit, this.delay)
  }

  submit() {
    Rails.fire(this.formTarget, 'submit')
  }

  get delay() {
    if(this.data.has("delay")) {
      return this.data.get("delay")
    } else {
      return 500
    }
  }
}
