import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "list", "filter" ]

  load() {
    fetch(this.resourcesUrl)
      .then(response => response.text())
      .then(html => {
        this.listTarget.innerHTML = html
      })
  }

  // private

  get resourcesUrl() {
    return `${this.data.get("url")}?${this.filterParams()}`
  }

  filterParams() {
    return $(this.filterTarget).select2('data')
      .map(selected => {
        return `framework_ids[]=${selected.id}`
      })
      .join('&')
  }
}
