import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "hiddenForm", "value", "hiddenField"]

  submit(event) {
    event.preventDefault()
    this.hiddenFieldTarget.value = this.valueTarget.value
    Rails.fire(this.hiddenFormTarget, 'submit')
  }
}
