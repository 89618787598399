import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "checkbox" ]

  toggle(event) {
    if(event.target.checked) {
      this.removeUnselectedItems()
    } else {
      this.addUnselectedItems()
    }
  }

  reset() {
    this.removeUnselectedItems()
  }

  // private

  removeUnselectedItems() {
    this.formTarget.querySelectorAll(this.inputSelector).forEach(input => {
      input.remove()
    })
  }

  addUnselectedItems() {
    this.checkboxTargets
      .filter(checkbox => !checkbox.checked)
      .forEach(checkbox => this.createHiddenElement(checkbox.value))
  }

  createHiddenElement(value) {
    const el = document.createElement('input')
    el.setAttribute('type', 'hidden')
    el.setAttribute('name', this.inputName)
    el.setAttribute('value', value)
    this.formTarget.appendChild(el)
  }

  get inputSelector() {
    return `input[name="${this.inputName}"]`
  }

  get inputName() {
    return this.data.get("inputName")
  }
}
